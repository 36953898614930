export const LOGIN_PROVIDERS = {
  local: 1,
  google: 2,
  discord: 3,
  facebook: 4,
  twitter: 5,
  twitch: 6,
  steam: 7,
  epic: 8,
  riot: 9,
  xbox: 10,
  github: 11,
  tiktok: 12,
};