import { toast } from "react-toastify";

const logout = async ({ t, path }: { t: any, path?: string }) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_AUTH_URL}/logout`, {
      method: 'POST',
      credentials: 'include' as RequestCredentials,
    });

    if (response.status === 200) {
      if (path) {
        window.location.href = path;
        return;
      }
      window.location.reload();
    } else {
      toast.error(`${t('error-logging-out')}`);
    }
  } catch (error) {
    
  }
}

export default logout;