"use client";

import useActivityChecker from "@/lib/utils/activity-checker";
import { FC, useEffect } from "react";
import { configureInterceptors } from "@/graph/axios.config";
import logout from "@/lib/utils/logout";
import { useTranslations } from "next-intl";

const WapperActivityChecker: FC = () => {
  const t = useTranslations('Home');
  const { handleFetch } = useActivityChecker();

  const handleInvalidToken = () => {
    logout({ t });
  }

  useEffect(() => {
    configureInterceptors(handleFetch, handleInvalidToken);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetch]);

  return null;
}

export default WapperActivityChecker;