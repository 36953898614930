import useAuthStore from '@/store/auth.store';
import { useState, useEffect } from 'react';

type UserStatus = 'online' | 'idle' | 'offline' | 'away';

const useActivityChecker = () => {
  const { userData: user } = useAuthStore();
  const [userStatus, setUserStatus] = useState<UserStatus>(!user ? 'offline' : 'online');
  let idleTimer: NodeJS.Timeout | null = null;

  const handleFetch = () => {
    if (userStatus === 'offline') {
      setUserStatus('online');
    }
    clearTimeout(idleTimer as NodeJS.Timeout);
    idleTimer = setTimeout(() => {
      if (userStatus !== 'away') {
        setUserStatus('idle');
      }
    }, 5 * 60 * 1000); // 5 minutes
  };

  useEffect(() => {
    const offlineTimer = setTimeout(() => {
      if (userStatus === 'idle') {
        setUserStatus('offline');
      }
    }, 15 * 60 * 1000); // 15 minutes

    console.log('ejecuta hook para guardar estado', userStatus);

    return () => {
      clearTimeout(idleTimer as NodeJS.Timeout);
      clearTimeout(offlineTimer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus]);

  return { userStatus, handleFetch };
};

export default useActivityChecker;
