import { UserMission } from "@/domain/quests.interface";
import { apgGraphQL } from "./apgApi"
import { RequestError } from "./general.endpoints";
import { operationRewardQueries } from "./graph-rewards-queries";
import { CardQuestProps } from "@/components/CardQuest/CardQuest";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export type UserCommon = {
  userId?: number;
  token?: string;
}

export type UserMissionParams = UserCommon & {
  status?: string[];
}

export type ClaimRewardParams = UserCommon & {
  missionId?: number;
}

const updateNewMissions = async ({
  userId,
  token
}: UserCommon): Promise<number> => {
  const { data: newMissions, errors } = await apgGraphQL(
    operationRewardQueries,
    'GetNewMissionsByUser',
    {
      "userId": userId
    }, 
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const insertMissions = newMissions.missions.map((mission: any) => ({
    "missionId": mission.id,
    "userId": userId
  }))

  const { data, errors: insertErrors } = await apgGraphQL(
    operationRewardQueries,
    'InsertUserMissions',
    {
      "objects": insertMissions
    }, 
    token
  );

  if (insertErrors) {
    throw getError(insertErrors[0] as RequestError)
  }

  return data.insertUserMissions.affected_rows;
}

const getUserMissions = async ({
  userId,
  status = ["pending", "completed"],
  token
}: UserMissionParams): Promise<CardQuestProps[]> => {
  const { data, errors } = await apgGraphQL(
    operationRewardQueries,
    'GetMissions',
    {
      "userId": userId,
      "status": status
    }, 
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userMissions.map((item: UserMission) => ({
    userId: item.userId,
    missionId: item.missionId,
    status: item.status,
    title: item.mission.name,
    description: item.mission.description,
    category: item.mission.category.name,
    coinsReward: item.mission.coinsReward,
    requirements: item.mission.requirements,
    counter: item.counter
  }));;
}

const claimReward = async ({
  userId,
  missionId,
  token
}: ClaimRewardParams): Promise<{
  totalCoins: number;
  success: boolean;
}> => {
  const { data, errors } = await apgGraphQL(
    operationRewardQueries,
    'UpdateMission',
    {
      "userId": userId,
      "missionId": missionId,
      "object": {
        "claimedAt": new Date().toISOString(),
      }
    }, 
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return {
    totalCoins: data.updateUserMissions.returning[0].user.totalCoins,
    success: true
  };
}

export const QuestEndpoints = {
  updateNewMissions,
  getUserMissions,
  claimReward
}
