"use client";

import React, { useEffect } from 'react';

import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import WapperActivityChecker from '@/store/activityChecker';
import useAuthStore from './auth.store';
import { ApgUser } from '@/domain/account.interface';
import { useQueryClient } from '@tanstack/react-query';
import { AccountEndpoints } from '@/graph/account.endpoints';
import { NotificationProvider } from '@/context/notification.context';
import { MessageProvider } from '@/context/messages.context';

export interface AppProviderProps {
  children: React.ReactNode;
  userData?: any;
  authToken?: string;
  isLogged?: boolean;
}

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: true,
  subscribeToChanges: true
});

export const AppProvider = ({ children, userData, authToken, isLogged }: AppProviderProps) => {
  const { setAuthtoken, setUserData, setIsLogged, setUserId } = useAuthStore();
  const queryClient = useQueryClient();

  const getSignedUser = async (userId: number) => {
    await queryClient.prefetchQuery({
      queryKey: ['apg-user-signed'], 
      queryFn: () => AccountEndpoints.getUserAccount(userId)
    });
  }
  
  useEffect(() => {
    growthbook.setAttributes({
      isBetaUser: userData?.isBetaUser,
      isVerified: userData?.isVerified,
      isWorker: userData?.isWorker,
      isPartner: userData?.isPartner
    })

    growthbook.loadFeatures();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authToken) {
      setAuthtoken(authToken || '');
      setUserData(userData as ApgUser)
      setIsLogged(isLogged || false)
      setUserId(userData?.id || 0)

      getSignedUser(userData?.id || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <NotificationProvider userId={userData?.id}>
      <MessageProvider userId={userData?.id} authToken={authToken as string}>
        <GrowthBookProvider growthbook={growthbook}>
          <WapperActivityChecker />
          <ProgressBar
            height="3px"
            color="#0076D1"
            shallowRouting
          />
          {children}
        </GrowthBookProvider>
      </MessageProvider>
    </NotificationProvider>
  );
};
