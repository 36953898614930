export const operationRewardQueries = `
  query GetNewMissionsByUser($userId: Int!) {
    missions: rewards_missions(where: {_not: {userMissions: {userId: {_eq: $userId}}}, type: {_in: ["unique", "starter", "daily", "weekly"]}, prevMissionId: {_is_null: true}}) {
      id
      name
    }
  }

  query GetMissions($userId: Int!, $status: [String!] = "") {
    userMissions: rewards_user_missions(where: {userId: {_eq: $userId}, status: {_in: $status}}, order_by: {mission: {id: asc}}) {
      counter
      missionId
      userId
      status
      endDate
      updatedAt
      createdAt
      mission {
        name
        description
        requirements
        prevMissionId
        id
        coinsReward
        categoryId
        type
        triggerType
        category {
          name
          description
          id
        }
      }
    }
  }  
  
  mutation UpdateMission($missionId: Int!, $userId: Int!, $object: rewards_user_missions_set_input = {}) {
    updateUserMissions: update_rewards_user_missions(where: {status: {_eq: "completed"}, missionId: {_eq: $missionId}, userId: {_eq: $userId}}, _set: $object) {
      affected_rows
      returning {
        status
        user {
          totalCoins
        }
      }
    }
  }

  mutation InsertCoinLog($objects: [rewards_coins_log_insert_input!] = {}) {
    insertCoinsLog: insert_rewards_coins_log(objects: $objects, on_conflict: {constraint: coins_Log_pkey, update_columns: action}) {
      affected_rows
      returning {
        userId
        id
        coins
        action
      }
    }
  }

  mutation InsertUserMissions($objects: [rewards_user_missions_insert_input!] = {}) {
    insertUserMissions: insert_rewards_user_missions(objects: $objects) {
      affected_rows
      returning {
        userId
        status
        missionId
      }
    }
  }
`