"use client";

import { createContext, useContext, FC, ReactNode, useState } from 'react';

interface OnboardingContextProps {
  isOnboarded: boolean;
  onboardingStep: string;
  setOnboardingStep: (step: string) => void;
}

const OnboardingContext = createContext<OnboardingContextProps | undefined>(undefined);

export const OnboardingProvider: FC<{ children: ReactNode, initialOnboarded: boolean, initialStep: string }> = ({ children, initialOnboarded, initialStep }) => {
  const [onboardingStep, setOnboardingStep] = useState(initialStep);

  return (
    <OnboardingContext.Provider value={{ isOnboarded: initialOnboarded, onboardingStep, setOnboardingStep }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};
