import { ApgUser, UserProfileTypeWithoutId, UserSection, UserSocials } from "@/domain/account.interface";
import { apgGraphQL } from "./apgApi"
import { operationGetAccountUser, operationUpdateUserSocials, operationInsertUserSocials, operationDeleteUserSocials, operationUpdateManyUserSocials, operationGetGeneral, operationProfileTypes, operationUserSections } from "./graph-queries";
import { RequestError } from "./general.endpoints";
import { LOGIN_PROVIDERS } from "@/domain/providers.enum";
import { RawDraftContentState } from "draft-js";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

const getUserAccount = async (userId: number): Promise<ApgUser> => {
  const { data, errors } = await apgGraphQL(
    operationGetAccountUser,
    'GetUserAccount',
    {
      "id": userId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return {
    ...data.user,
    aboutRichTemp: data.user.aboutRich
  };
}

const getUserAccountByUsername = async (username: string): Promise<ApgUser> => {
  
  const { data, errors } = await apgGraphQL(
    operationGetAccountUser,
    'GetUserAccountByUsername',
    {
      "username": username
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.user[0];
}

const updateUserAccount = async (userId: number, set: Partial<ApgUser>, token: string): Promise<ApgUser> => {
  const { data, errors } = await apgGraphQL(
    operationGetAccountUser,
    'UpdateUserAccount',
    {
      "id": userId,
      "_set": set
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updateUserByPk;
}

export type InsertUserToDeleted = {
  requestDate?: string,
  deletionReason: RawDraftContentState,
  deletionDate: string,
  deletionConfirmation: boolean,
  userId?: number
  id?: number
}

const insertUserToDeleted = async (object: InsertUserToDeleted, token: string): Promise<InsertUserToDeleted> => {
  const { data, errors } = await apgGraphQL(
    operationGetAccountUser,
    'InsertUserToDeleted',
    {
      "object": object
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserToDeleted;
}

const deleteUserToDeleted = async (userId: number, token: string): Promise<number> => {
  const { data, errors } = await apgGraphQL(
    operationGetAccountUser,
    'CancelElimination',
    {
      "userId": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.cancelElimination.affectedRows;
}

const updateUserSocial = async (userId: number, socialId: number, set: Partial<UserSocials>, token: string): Promise<UserSocials> => {
  try {
    const { data } = await apgGraphQL(
      operationUpdateUserSocials,
      'UpdateUserSocial',
      {
        "where": {
          "socialId": { "_eq": socialId },
          "userId": { "_eq": userId }
        },
        "_set": set
      },
      token
    )

    return data.insertUserSocial;
  } catch (error) {
    throw getError(error as RequestError)
  }
}

const updateManyUserSocial = async (updates: Record<string, any>, token: string): Promise<UserSocials> => {
  const { data, errors } = await apgGraphQL(
    operationUpdateManyUserSocials,
    'UpdateUserSocials',
    {
      "updates": updates
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updateManySocials;
}

const insertUserSocial = async (userId: number, set: Partial<UserSocials>, token: string): Promise<UserSocials> => {
  const { data, errors } = await apgGraphQL(
    operationInsertUserSocials,
    'InsertUserSocial',
    {
      "userId": userId,
      "socialId": set.socialId,
      "link": set.link,
      "order": set.order
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserSocial;
}

const deleteUserSocial = async (userId: number, socialId: number, token: string): Promise<{ affectedRows: number, returning: any[]}> => {
  const { data, errors } = await apgGraphQL(
    operationDeleteUserSocials,
    'DeleteUserSocial',
    {
      "where": {
        "socialId": { "_eq": socialId },
        "userId": { "_eq": userId }
      },
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.deleteUserSocial;
}

const deleteLoginProvider = async (userId: number, token: string, providerName: string): Promise<{ affected_rows: number, returning: any[]}> => {
  const providerNumber = LOGIN_PROVIDERS[providerName as keyof typeof LOGIN_PROVIDERS];
  
  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'DeleteUserLoginProvider',
    {
      "where": {
        "loginProviderId": { "_eq": providerNumber },
        "userId": { "_eq": userId }
      },
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.deletedUserLogin;
}

const insertOrUpdateProfileTypes = async (objects: UserProfileTypeWithoutId[], token: string): Promise<any> => {
  const { data, errors } = await apgGraphQL(
    operationProfileTypes,
    'InsertOrUpdateProfileTypes',
    {
      "objects": objects
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updatedUserProfileTypes;
}

const getUserSections = async (userId: number, token: string): Promise<UserSection[]> => {
  const { data, errors } = await apgGraphQL(
    operationUserSections,
    'GetUserSections',
    {
      "where": {
        "userId": { "_eq": userId }
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userSections;
}

const updateUserSectionVisibility = async (userId: number, section: UserSection, token: string): Promise<UserSection[]> => {
  const { data, errors } = await apgGraphQL(
    operationUserSections,
    'UpdateUserSectionVisibility',
    {
      "userId": userId,
      "sectionId": section.sectionId,
      "_set": {
        "isVisible": !section.isVisible
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userSections;
}

const updateManyUserSections = async (updates: Record<string, any>, token: string): Promise<UserSection[]> => {
  const { data, errors } = await apgGraphQL(
    operationUserSections,
    'UpdateUserSections',
    {
      "updates": updates
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updateSections;
}

export const AccountEndpoints = {
  getUserAccount,
  getUserAccountByUsername,
  updateUserAccount,
  updateUserSocial,
  updateManyUserSocial,
  insertUserSocial,
  deleteUserSocial,
  deleteLoginProvider,
  insertOrUpdateProfileTypes,
  insertUserToDeleted,
  deleteUserToDeleted,
  getUserSections,
  updateUserSectionVisibility,
  updateManyUserSections
}
