import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/src/components/KonamiCode/KonamiCode.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/OnboardingContext.tsx");
import(/* webpackMode: "eager" */ "/app/src/layouts/LayoutOnboarding/LayoutOnboarding.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/utils/provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/store/index.tsx")