"use client";

import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styles from '@/styles/Home.module.css'
import classNames from 'classnames';
import { ApgUser } from '@/domain/account.interface';
import { usePathname } from 'next/navigation';
import { MessageKeys, useTranslations } from 'next-intl';
import { cn } from '@apg.gg/core/lib/cn';
import { useOnboarding } from '@/context/OnboardingContext';
import Tooltip from '@apg.gg/core/lib/Tooltip';
import useAuthStore from '@/store/auth.store';
import { useQuery } from '@tanstack/react-query';
import { QuestEndpoints } from '@/graph/profile-quests.endpoints';

interface LayoutOnboardingProps {
  children: React.ReactNode;
  locale: string;
  userProfile?: ApgUser | null;
}

const LayoutOnboarding: FC<LayoutOnboardingProps> = ({
  children,
  locale
}) => {
  const t = useTranslations();
  const { onboardingStep } = useOnboarding();
  const { authToken, userData } = useAuthStore();
  const pathname = usePathname();

  const { data: newMissions } = useQuery({
    queryKey: ["apg-user-new-missions", userData?.id],
    queryFn: async () => await QuestEndpoints.updateNewMissions({ userId: userData?.id, token: authToken }),
    staleTime: 1000 * 60 * 20,
    enabled: (!!userData?.id && !!authToken),
  })

  const mainClasses = pathname.includes('/auth') ? "flex flex-col items-center justify-center gradient-4" : "";

  const classNamesMain = cn(
    "px-4 w-full h-[calc(100vh-56px)] overflow-y-auto scroll-smooth",
    mainClasses,
    pathname === `/${locale}` ? 'px-0' : ''
  );

  const totalSteps = 4;
  const stepMap: Record<string, number> = { basics: 1, 'profile-types': 2, games: 3, follows: 4 };
  const currentStepNumber = stepMap[onboardingStep as keyof typeof stepMap] || 1;

  return (
    <>
      <main className={classNames(
        styles.main,
        "scrollbar-trigger"
      )}>
        <nav className="bg-black shadow-lg w-full z-50 flex items-center justify-start gap-2 px-4 py-2 h-14">
          <div className="flex items-center justify-start flex-1">
            <div className="flex h-full">
              <Link href="/">
                <Image src="/assets/resources/isotipo-white.png" alt='APG.gg' className='h-10 w-auto' width={162} height={61} />
              </Link>
            </div>
          </div>

          <div className="text-title-sm flex items-center gap-2 ml-auto">
            {Array.from({ length: totalSteps }, (_, i) => i + 1).map((step, index) => (
              <Tooltip
                key={index}
                text={t(`onboarding.step-${index + 1}` as MessageKeys<any, any>)}
                placement="bottom"
                className={cn(
                  "text-body-sm",
                  currentStepNumber >= index + 1 ? "bg-blue" : "bg-black-800"
                )}
              >
                <div
                  className={classNames(
                    "flex items-center justify-center w-12 h-2 rounded-full",
                    currentStepNumber >= index + 1 ? "bg-blue" : "bg-black-800"
                  )}
                />
              </Tooltip>
            ))}
            {t(`onboarding.step-${currentStepNumber}` as MessageKeys<any, any>)}
          </div>
        </nav>
        <div className={classNamesMain}>
          {children}
        </div>
      </main>
    </>
  )
}

export default LayoutOnboarding;