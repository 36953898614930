import { useContext, createContext, useEffect, useState, useRef, useCallback } from "react"
import { IMessage } from '@novu/headless';
import _ from "lodash";
import { ApolloClient, gql, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { LastMessage } from "@/graph/messages/endpoints";

export type ApgMessage = IMessage & {
  id: string
}

// Define the context type here
interface IMessageContext {
  newMessageInitializer: (lastMessage: LastMessage) => void
  chatsInitializer: () => void
  unseenCountListener: () => void
  markChatMessagesAsRead: (chatId: string) => void
  newMessage: any
  setNewMessage: React.Dispatch<React.SetStateAction<ApgMessage[]>>
  chatsOrderUpdated: boolean
  setChatsOrderUpdated: React.Dispatch<React.SetStateAction<boolean>>
  hideBadge: boolean
  setHideBadge: React.Dispatch<React.SetStateAction<boolean>>
  isInitialized: boolean
  setIsInitialized: React.Dispatch<React.SetStateAction<boolean>>
  unseenCount: number
  setUnseenCount: React.Dispatch<React.SetStateAction<number>>
  // messages: ApgMessage[]
  // setMessages: React.Dispatch<React.SetStateAction<ApgMessage[]>>
}

const MessageContext = createContext<IMessageContext | undefined>(undefined);

const MessageProvider: React.FC<{ children: React.ReactNode, userId: number, authToken: string }> = ({ children, userId, authToken }) => {
  const [newMessage, setNewMessage] = useState<any>(null);
  const [chatsOrderUpdated, setChatsOrderUpdated] = useState<boolean>(false);
  const [hideBadge, setHideBadge] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [unseenCount, setUnseenCount] = useState(0);

  const messageServiceRef = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

  useEffect(() => {
    const wsLink = new GraphQLWsLink(createClient({
      url: `${process.env.NEXT_PUBLIC_HASURA_GRAPHQL_WSS}`,
      lazy: true,
      connectionParams: () => ({ headers: { Authorization: `Bearer ${authToken}` } })
    }))

    const client = new ApolloClient({
      link: wsLink,
      cache: new InMemoryCache(),
    });

    messageServiceRef.current = client;
    setIsInitialized(true);

    return () => {
      client.stop();
    }
  }, [authToken]);

  const newMessageInitializer = useCallback((lastMessage: LastMessage) => {
    const messageService = messageServiceRef.current;
    if (messageService) {
      setHideBadge(false);

      const GET_CHAT_MESSAGES_SUBSCRIPTION = gql`
        subscription getChatMessages {
          message: communication_messages_stream(batch_size: 1, cursor: {initial_value: {id: ${lastMessage?.id}}}, where: {recipients: {chatId: {_eq: "${lastMessage?.chatId}"}, userId: {_eq: ${userId}}}}) {
            authorId
            id
          }
        }
      `;

      const observable = messageService.subscribe({
        query: GET_CHAT_MESSAGES_SUBSCRIPTION,
      });

      const subscription = observable.subscribe({
        next: (data) => setNewMessage(data),
        error: (error) => console.error(error),
        complete: () => console.log('complete'),
      });

      return () => {
        subscription.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const chatsInitializer = useCallback(() => {
    const messageService = messageServiceRef.current;
    if (messageService) {
      setHideBadge(false);

      const GET_CHAT_NEW_ORDER = gql`
        subscription getChatsOrder {
          communication_chats(where: {chatUsers: {userId: {_eq: ${userId}}}}, order_by: {updatedAt: desc}) {
            chatName
            id
            status
            updatedAt
            type
            chatUsers {
              user {
                username
                profileImage
                id
              }
            }
          }
        }
      `;

      const observable = messageService.subscribe({
        query: GET_CHAT_NEW_ORDER,
      });

      const subscription = observable.subscribe({
        next: (data) => setChatsOrderUpdated(true),
        error: (error) => {
          console.error(error)
          setChatsOrderUpdated(false)
        },
        complete: () => console.log('complete'),
      });

      return () => {
        subscription.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const unseenCountListener = useCallback(() => {
    const messageService = messageServiceRef.current;
    if (messageService) {
      const GET_UNSEEN_COUNT = gql`
        subscription MySubscription {
          communication_chats_aggregate(where: {messages: {seen: {_eq: false}, authorId: {_neq: ${userId}}}}) {
            aggregate {
              count
            }
            nodes {
              id
              status
            }
          }
        }
      `;

      const observable = messageService.subscribe({
        query: GET_UNSEEN_COUNT,
      });

      const subscription = observable.subscribe({
        next: (data) => setUnseenCount(data.data.communication_chats_aggregate.aggregate.count),
        error: (error) => {
          console.error(error)
          setUnseenCount(0)
        },
        complete: () => console.log('complete'),
      });

      return () => {
        subscription.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const markChatMessagesAsRead = useCallback((chatId: string) => {
    const messageService = messageServiceRef.current;
    if (messageService) {
      const MARK_AS_READ = gql`
        mutation markAsRead {
          update_communication_messages(where: {chatId: {_eq: "${chatId}"}, seen: {_eq: false}, authorId: {_neq: ${userId}}}, _set: {seen: true}) {
            affected_rows
            returning {
              id
            }
          }
        }
      `;

      messageService.mutate({
        mutation: MARK_AS_READ,
      });

      return () => {
        console.log('APG: markChatMessagesAsRead');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <MessageContext.Provider value={{ 
      newMessageInitializer, 
      chatsInitializer,
      markChatMessagesAsRead,
      unseenCountListener,
      newMessage, 
      setNewMessage, 
      chatsOrderUpdated, 
      setChatsOrderUpdated, 
      hideBadge, 
      setHideBadge,
      isInitialized,
      setIsInitialized,
      unseenCount,
      setUnseenCount
    }}>
      {children}
    </MessageContext.Provider>
  )
}

const useMessage = () => useContext(MessageContext) as IMessageContext;

export { useMessage, MessageProvider };