import { useContext, createContext, useEffect, useState, useRef, useCallback } from "react"
import { HeadlessService, IMessage } from '@novu/headless';
import { useTranslations } from "next-intl";
import _ from "lodash";
import { toast } from "react-toastify";

export type ApgNotification = IMessage & {
  id: string
}

// Define the context type here
interface INotificationContext {
  unseenCountInitializer: () => void
  unseenCount: number
  setUnseenCount: React.Dispatch<React.SetStateAction<number>>
  notifications: ApgNotification[]
  setNotifications: React.Dispatch<React.SetStateAction<ApgNotification[]>>
  markNotificationsAsRead: (messageIds: string | string[]) => void
  markAllMessagesAsRead: (feedId?: string) => void
  deleteNotification: (messageId: string) => void
  pageNum: number
  setPageNum: React.Dispatch<React.SetStateAction<number>>
  fetchNotifications: () => void
  fetchUnseenCount: () => void
  hasMore: boolean
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>
  isInitialized?: boolean
  setIsInitialized?: React.Dispatch<React.SetStateAction<boolean>>,
  messagesIds? : string[],
  setMessagesIds?: React.Dispatch<React.SetStateAction<string[]>>
}

const NotificationContext = createContext<INotificationContext | undefined>(undefined);

const NotificationProvider: React.FC<{ children: React.ReactNode, userId: number }> = ({ children, userId }) => {
  const t = useTranslations('notifications');
  const [notifications, setNotifications] = useState<ApgNotification[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [unseenCount, setUnseenCount] = useState(0);
  const [messagesIds, setMessagesIds] = useState<string[]>([]);

  const headlessServiceRef = useRef<HeadlessService | null>(null);
  const [pageNum, setPageNum] = useState(0);

  const fetchNotifications = () => {
    const headlessService = headlessServiceRef.current;
    if (headlessService) {
      headlessService.fetchNotifications({
        listener: ({ data, error, isError, isFetching, isLoading, status }) => {
          // Handle the state of the fetching process and errors here.
        },
        onSuccess: (response) => {
          setHasMore(response.hasMore);
          setNotifications(response.data as ApgNotification[]);
          setIsInitialized(true);

          const messagesIds = response.data.map((notification) => notification._id);
          setMessagesIds(messagesIds);
        },
        query: {
          limit: 20,
        },
        page: pageNum, // page number to be fetched
      });
    }
  }

  const fetchUnseenCount = useCallback(() => {
    const headlessService = headlessServiceRef.current;
    if (headlessService) {
      headlessService.fetchUnseenCount({
        listener: (data) => {
          // Handle the state of the fetching process and errors here.
        },
        onSuccess: (data) => {
          setUnseenCount(data?.count || 0);
        }
      });
    }
  }, [])

  useEffect(() => {
    const headlessService = new HeadlessService({
      applicationIdentifier: `${process.env.NEXT_PUBLIC_NOVU_NOTIFICATION_APP_IDENTIFIER}`,
      subscriberId: String(userId),
      backendUrl: `${process.env.NEXT_PUBLIC_NOVU_API_URL}`,
      socketUrl: `${process.env.NEXT_PUBLIC_NOVU_WS_URL}`,
    });

    headlessService.initializeSession({
      listener: (res) => {
      },
      onSuccess: (session) => {
        headlessServiceRef.current = headlessService;
        fetchUnseenCount();
        fetchNotifications();
      },
      onError: (error) => {
        console.log("headlessSice error:", error);
      },
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // Function to mark notifications as read
  const markNotificationsAsRead = (messageIds: string | string[]) => {
    if (!Array.isArray(messageIds)) {
      messageIds = [messageIds];
    }

    const headlessService = headlessServiceRef.current;

    if (headlessService) {
      headlessService.markNotificationsAsRead({
        messageId: messageIds,
        listener: (result: any) => {
        },
        onSuccess: (message: any) => {
          fetchNotifications();
          fetchUnseenCount();
        },
        onError: (error: any) => {
          console.error('Error marking notifications as read:', error);
        },
      });
    }
  };

  const deleteNotification = (messageId: string) => {
    const headlessService = headlessServiceRef.current;
    if (headlessService) {
      headlessService.removeNotification({
        messageId: messageId,
        listener: function (result: any) {
        },
        onSuccess: function (message: any) {
          t('delete-success')
        },
        onError: function (error: any) {
          console.error(error);
          t('delete-error')
        }
      });

    }
  }

  const markAllMessagesAsRead = (feedId?: string) => {
    const headlessService = headlessServiceRef.current;
    if (headlessService) {
      headlessService.markAllMessagesAsRead({
        listener: (result: any) => {
          // Handle the state of the fetching process and errors here.
        },
        onSuccess: (count: number) => {
          fetchNotifications();
          fetchUnseenCount();
          setUnseenCount(count || 0);
          toast.success(t('mark-all-as-read-success'))
        },
        onError: (error: any) => {
          console.error('Error marking all messages as read:', error);
          toast.error(t('mark-all-as-read-error'))
        },
        feedId: feedId, // Pass the feed ID here, it can be an array or a single ID
      });
    }
  };

  const unseenCountInitializer = () => {
    const headlessService = headlessServiceRef.current;
    if (headlessService) {
      console.log('APG: Listening to unseen count change event.')
      headlessService.listenUnseenCountChange({
        // this will run every time there's a change in the `unseen_count` in real-time
        listener: (unseenCount: number) => {
          setUnseenCount(unseenCount);
        },
      });
    }
  }

  return (
    <NotificationContext.Provider value={{ messagesIds, isInitialized, unseenCountInitializer, unseenCount, setUnseenCount, notifications, setNotifications, markNotificationsAsRead, markAllMessagesAsRead, deleteNotification, pageNum, setPageNum, fetchNotifications, fetchUnseenCount, hasMore, setHasMore }}>
      {children}
    </NotificationContext.Provider>
  )
}

const useNotification = () => useContext(NotificationContext) as INotificationContext;

export { useNotification, NotificationProvider };